@font-face {
  font-family: "massenet_boldbold";
  src: url("/assets/styles/fonts/massenet/massenetbold-bold-webfont.woff2")
      format("woff2"),
    url("/assets/styles/fonts/massenet/massenetbold-bold-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}


/* MEDIAQUERIES */

/* petit écran sm (téléphone mode paysage , 576px et plus) */
@media (min-width: 576px) {


    /* slogan */

    .slogan {
        font-size: 3em!important;
    }
    /* forme et taille de la ul de droite */

.liens_droits {
    margin-top: 20px;
    font-size: 2em!important;
}

.liens_droits a {
  padding: 10px 10px!important;
}


#map_et_diapo {
    flex-direction: column!important;
}

#map {
    margin: 0 auto!important;
}

#carouselExampleControls {
    margin: 0 auto!important;
}

.orange {
    width: auto!important;
    padding: 10px 10px;
}

.greu {
    width: auto!important;
    padding: 10px 10px;
}

.noir {
    width: auto!important;
    padding: 10px 10px;
}

/* forme et taille de la ul de gauche */

.liens_gauche {
   margin: 0 auto;
   font-size: 2em!important;
}

.liens_gauche li {
    width: auto!important;
}

.punaise_map {
    display: none;
}
}

/* petit écran md (tablette , 768px et plus) */
@media (min-width: 768px) {
    
}

/* écran large ld (ordinateur , 992px et plus) */
@media (min-width: 992px) {

    
    /* slogan */

    .slogan {
        font-size: 2em!important;
    }
    
    #map_et_diapo {
        flex-direction: row!important;
    }

    #map {
        margin: 10px auto!important;
    }
    
    #carouselExampleControls {
        margin: 10px auto!important;
    }

    .punaise_map {
        display: block;
    }

    .liens_droits {
        margin-top: 15px!important;
        font-size: 1em!important;
    }

    .liens_gauche {
        margin-top: 15px!important;
        margin-left: 15px!important;
        font-size: 1em!important;
    }
}

/* écran extra large xl (ordinateur grand écran , 1200px et plus) */
@media (min-width: 1200px) {
    #burger {
        display:none;
    }

    .punaise_map {
        display: block;
    }
}



.font_grand {
  font-size: 4em!important;
}

img {
  cursor: pointer;
}

.image_grande {
  width: 700px !important;
  height: 700px !important;
}

.image_petite {
  width: 300px !important;
  height: 300px !important;
}

.A_titre {
  width: 10px;
  height: auto;
  color: #ffffff;
  background-color: #f0840a;
  border-radius: 10px 10px 10px 10px;
}

.liens_blancs a {
  color: #ffffff !important;
  text-decoration: none !important;
}

.liens_noirs a {
  color: #000000 !important;
  text-decoration: none !important;
}

.bouton {
  background-color: darkcyan !important;
  color: #ffffff !important;
  padding: 2px 5px;
  border-radius: 10px;
  
}

.bouton:hover {
  background-color: rgb(214, 144, 79) !important;
  color: #ffffff !important;
}

ul a {
  color: white !important;
}

a:hover {
  color: #000000;
}

.homeNav li:hover {
  background-color: rgb(7, 7, 7);
}

#deconnexion:hover {
  background-color: #cc1b1b!important;
  border-color: #cc1b1b!important;
}

.massenet {
  font-family: massenet_boldbold, arial !important;
}

.titre_profil {
  width: 20px;
  height: auto;
  padding: 5px;
  border-radius: 10px 10px 10px 10px;
  background-color: rgb(199, 81, 13);
  font-size: 1.2em;
  color: #ffffff;
}

.slogan {
  font-size: 2em;
}

.lien:hover {
  text-decoration: none;
}

.titre {
  font-family: massenet_boldbold, arial;
  font-size: 5em;
}

.liste_nav {
  padding: 0 10px !important;
}

a:hover {
  color: black;
}

.white a {
  color: #ffffff !important;
}
/* liens */

li {
  border-radius: 10px 10px 10px 10px;
  text-align: center;
  color: #ffffff !important;
  padding: 2px;
}

/* liens de gauche */

.liens_gauche li {
  padding: 2px !important;
  margin: 2px;
}

.rouge {
  width: 150px;
  height: auto;
  background-color: rgb(163, 13, 13);
  border-radius: 10px 10px 10px 10px;
}

.rouge a {
  color: #ffffff;
}

.vert {
  width: 150px;
  height: auto;
  background-color: rgb(11, 97, 54);
  border-radius: 10px 10px 10px 10px;
}

.vert a {
  color: #ffffff;
}

.bleu {
  width: 150px;
  height: auto;
  background-color: #0f96e4;
  border-radius: 10px 10px 10px 10px;
}

.bleu a {
  color: #ffffff;
  font-family: massenet, arial;
}

.orange {
  width: 150px;
  height: auto;
  background-color: rgb(199, 81, 13);
  border-radius: 10px 10px 10px 10px;
}

.orange a {
  color: #ffffff;
}

#registration {
  text-align: center;
}

#registration a {
  color: #ffffff;
}

#connexion a {
  color: #ffffff !important;
}


/* liens de droite */


.violet {
  width: 150px;
  height: auto;
  background-color: rgb(133, 27, 106);
  border-radius: 10px 10px 10px 10px;
}

.violet a {
  color: #ffffff;
  text-align: center;
}

.vertdo {
  width: 150px;
  height: auto;
  background-color: rgb(27, 133, 124);
  border-radius: 10px 10px 10px 10px;
}

.vertdo a {
  color: #ffffff;
  text-align: center;
}

.greu {
  width: 150px;
  height: auto;
  background-color: rgb(76, 95, 100);
  border-radius: 10px 10px 10px 10px;
}

.greu:hover {
  background-color: rgb(47, 128, 128);
}

.greu a {
  color: #ffffff;
  text-align: center;
}

.taupe {
  width: 150px;
  height: auto;
  background-color: rgb(95, 79, 25);
  border-radius: 10px 10px 10px 10px;
}

.taupe:hover {
  background-color: rgb(47, 128, 128);
}

.taupe a {
  color: #ffffff;
  text-align: center;
}

.noir {
  width: 150px;
  height: auto;
  background-color: rgb(39, 38, 36);
  border-radius: 10px 10px 10px 10px;
}

.noir a {
  color: #ffffff;
  text-align: center;
}

.bleudeco {
  width: 150px;
  height: auto;
  background-color: rgb(16, 12, 248);
  border-radius: 10px 10px 10px 10px;
}

.bleudeco a {
  color: #ffffff;
  text-align: center;
}

#deco {
  padding: 2px;
}

.avatar {
  text-align: center;
}

.avatar img {
  border-radius: 50%;
  width: 150px;
  height: auto;
}

#footer {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;
    width: 100%;
    position: relative;
}

.foot {
  height: 180px !important;
  width: 100%;
  padding: 25px;
  text-align: center;
  margin-bottom: 0;
}

#map {
  border-radius: 10px 10px 10px 10px;
  box-shadow: 5px 5px 5px 5px;
  width: 100%;
  height: 500px;
  z-index: 9;
}

.punaise_map {
  position: absolute;
  z-index: 9;
  top: 280px;
  left: 660px;
}

/* infos membre sur la carte */
#infoMember {
  font-family: sans-serif, arial;
  position: absolute;
  width: 25%;
  height: auto;
  border-radius: 50%;
  text-align: center;
  background-color: rgb(143, 184, 238);
  border: 2.5px solid #ffff;
  color: #ffff;
  left: 35px;
  top: 700px;
  display: none;
  z-index: 10;
}

.no_point {
  list-style: none;
  margin: 10px auto;
  padding: 10px;
}

.carousel-inner {
  margin-top: 90px;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 5px 5px 5px 5px;
}

.carousel img {
  max-width: 100%;
  max-height: 400px;
}

.images {
  width: 50% !important;
  height: auto;
}


.avatar {
    width: 20%;
    height: auto;
    margin:0 auto;
}

/* pour ne plus avoir les liens a la con dans l'upload d'image */
.form-group a {
  display: none!important;
}

.listeGaleries {
    line-height: 3;
    text-align: center;
}

.listeGaleries a {
    text-decoration: none!important;
}

/* faire disparaitre la checkbox du formulaire d'inscription */
#registration_avatarFile_delete {
    display: none;
}

.vich-file {
    color: #ffffff;
}

/* mettre un fond vert au titre "categories" */

.col-form-label {
    width: 150px;
    height: auto;
    background-color: rgb(11, 97, 54);
    color: #ffffff;
    border-radius: 10px 10px 10px 10px;
    text-align: center;
}

.custom-file {
    margin-top: 20px;
}

/* bouton browse */

.custom-file input {
    cursor: pointer;
}
 
.custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse";
  }
  
  .custom-file-input:lang(fr) ~ .custom-file-label::after {
    content: "Parcourir";
  }

/* inscription activation */
.activation_link {
    text-decoration: none;
    color: red;
}

.activation_text {
    margin: 0 auto;
}

/* --  ==== MEDIAQUERIES ====  -- */

@media all and (max-width: 1024px) {
  /* ipad */

  .flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  #info {
    display: none;
    position: absolute;
    width: 60%;
    height: 70px;
    line-height: 4;
    font-family: milkshake, arial, time;
    color: #ffffff;
    font-size: 1em;
    background-color: rgb(69, 126, 231);
    text-align: center;
    z-index: 1;
    top: 400px;
    left: 150px;
  }

 
  #pas_vu {
    margin-top: 10px;
  }
}
@media all and (max-device-width: 480px) {
  /* smartphone */

  input:checked ~ nav {
    height: auto;
    transition-delay: 0s;
  }

  element.style {
    display: block;
  }

  #info {
    display: none;
    position: absolute;
    width: 80%;
    height: 70px;
    line-height: 4;
    font-family: milkshake, arial, time;
    color: #ffffff;
    font-size: 1em;
    background-color: rgb(69, 126, 231);
    text-align: center;
    z-index: 1;
    top: 50px;
    left: 10px;
  }

  #map {
    width: 90%;
    height: 500px;
    margin: 0 auto;
  }

  /* infos membre sur la carte */
  #infoMember {
    width: 25%;
    height: auto;
    left: 135px;
    top: 10px;
    display: none;
    z-index: 10;
  }
}
